import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/logo.png"

const Header = ({ siteTitle }) => (
    <header className="c12">
      <div className="hero">
          <div className="header">
            {/* <div className="made-by-me"></div> */}
            <Link to="/">
              <img alt="Eden Adler logo" src={Logo} className="ea-logo"/>
            </Link>
            <Link to="/"><h3 className="medium content-web">Eden Adler</h3></Link>
            <nav className="nav">
                <h5 className="option"><Link activeClassName="active" to="/">Portfolio</Link></h5>
                <h5 className="option"><Link activeClassName="active" to="/about">About</Link></h5>
                <h5 className="option"><Link activeClassName="active" to="/press">Press</Link></h5>
                <h5 className="option"><Link activeClassName="active" to="/blog">Blog</Link></h5>
            </nav>
          </div>
      </div>
    </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
