/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import Instagram from "../icons/instagram.svg"
import Twitter from "../icons/twitter.svg"
import Medium from "../icons/medium.svg"
import LinkedIn from "../icons/linkedin.svg"
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)


  return (
    <>
      <div className="grid">
        <Header className="c12" siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main className="c12">{children}</main>
        <footer className="c12 grid">
          <div className="c6-left">
              <p>As a curious person, I enjoy connecting with all kinds of people. Feel free to reach out eden.n.adler@gmail.com.</p>
          </div>
          <div className="social">
              <a href="https://www.instagram.com/edenadler/" target="_blank"><img alt="Instagram icon" src={Instagram} className="icons"/></a>
              <a href="https://twitter.com/edenadler" target="_blank"><img alt="Twitter icon" src={Twitter} className="icons"/></a>
              <a href="https://medium.com/@edenadler" target="_blank"><img alt="Medium icon" src={Medium} className="icons"/></a>
              <a href="https://linkedin.com/in/edenadler" target="_blank"><img alt="LinkedIn icon" src={LinkedIn} className="icons"/></a>
          </div>

          {/* <a href="#top" aria-label="scroll to top" className="content-web no-display scroll-to-top"></a> */}
        </footer>
        {/* <p className="credit">Built from scratch by Eden Adler</p> */}
      </div>
      <Helmet>
        <script src="https://code.jquery.com/jquery-3.4.1.min.js" type="text/javascript" />
        <link href="https://cdnjs.cloudflare.com/ajax/libs/hover.css/2.1.0/css/hover-min.css" rel="stylesheet" />
        <script src={withPrefix('/scripts/scroll.js')} type="text/javascript" />
      </Helmet>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
